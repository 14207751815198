import React from 'react';
import {
  List,
  TextField,
  ChipField,
  BooleanField,
  DeleteButton,
  ImageField,
  FunctionField,
  DatagridConfigurable,
  useTheme
} from 'react-admin';
import { blue, red } from '@material-ui/core/colors';
import { TimerField } from './TimerField';
import { Link } from 'react-router-dom';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RowStyle from './RowStyle';
import { Box, Tooltip } from '@mui/material';
import ListActions from './ListActions';
import AppPanel from './AppPanel';
import BotAppsFilter from './BotAppsFilter';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BanTrueIcon = () => <div style={{ color: red[500], fontWeight: 'bold' }}>BAN</div>;
const MarkTrueIcon = () => <div style={{ color: blue[500], fontWeight: 'bold' }}>MARK</div>;

const InBotTrue = () => <CheckIcon color='success' />;
const InBotFalse = () => <CloseIcon sx={{ color: red[700] }} />;

const BotAppsList = (props: any) => {
  const [theme, ] = useTheme();

  return (
    <Box>
      <List
        {...props}
        filters={<BotAppsFilter />}
        actions={<ListActions />}
      >
        <DatagridConfigurable
          preferenceKey="botApps.datagrid"
          rowStyle={(record) => RowStyle(record, theme as any)}
          rowClick='edit'
          expandSingle
          expand={(data: any) => AppPanel(data)}
        >
          <TextField source='demoId' label='#' />
          <ImageField
            source='logo'
            label='Icon'
            title='name'
            sx={{
              '& img': {
                maxWidth: 60,
                maxHeight: 60,
                objectFit: 'contain',
                borderRadius: 10,
                border: theme === 'dark' ? 'none' : '2px solid black',
              },
            }}
          />
          <FunctionField
            label='Name'
            render={(record: any) => {
              return (
                <Link
                  target='_blank'
                  onClick={(event: any) => event.stopPropagation()}
                  style={{
                    color: theme === 'dark' ? 'white' : 'black',
                    textDecoration: 'none',
                  }}
                  to={`https://mirapanelapps.com/#/botApps/${record.id}`}
                >
                  {record.name}
                </Link>);
            }}
          />
          <FunctionField
            label='Category'
            render={(record: any) => {
              if (record.os === 'Android') {
                return (
                  <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' fill='#A4C639'
                       className='bi bi-android2' viewBox='0 0 16 16'>
                    <path
                      d='m10.213 1.471.691-1.26q.069-.124-.048-.192-.128-.057-.195.058l-.7 1.27A4.8 4.8 0 0 0 8.005.941q-1.032 0-1.956.404l-.7-1.27Q5.281-.037 5.154.02q-.117.069-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.7 3.7 0 0 0 3.5 5.02h9q0-1.125-.623-2.072a4.27 4.27 0 0 0-1.664-1.476ZM6.22 3.303a.37.37 0 0 1-.267.11.35.35 0 0 1-.263-.11.37.37 0 0 1-.107-.264.37.37 0 0 1 .107-.265.35.35 0 0 1 .263-.11q.155 0 .267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264m4.101 0a.35.35 0 0 1-.262.11.37.37 0 0 1-.268-.11.36.36 0 0 1-.112-.264q0-.154.112-.265a.37.37 0 0 1 .268-.11q.155 0 .262.11a.37.37 0 0 1 .107.265q0 .153-.107.264M3.5 11.77q0 .441.311.75.311.306.76.307h.758l.01 2.182q0 .414.292.703a.96.96 0 0 0 .7.288.97.97 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182q0 .414.292.703a.97.97 0 0 0 .71.288.97.97 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76q.436 0 .749-.308.31-.307.311-.75V5.365h-9zm10.495-6.587a.98.98 0 0 0-.702.278.9.9 0 0 0-.293.685v4.063q0 .406.293.69a.97.97 0 0 0 .702.284q.42 0 .712-.284a.92.92 0 0 0 .293-.69V6.146a.9.9 0 0 0-.293-.685 1 1 0 0 0-.712-.278m-12.702.283a1 1 0 0 1 .712-.283q.41 0 .702.283a.9.9 0 0 1 .293.68v4.063a.93.93 0 0 1-.288.69.97.97 0 0 1-.707.284 1 1 0 0 1-.712-.284.92.92 0 0 1-.293-.69V6.146q0-.396.293-.68' />
                  </svg>
                );
              } else if (record.os === 'iOS') {
                return (<svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' fill='#C0C0C0'
                             className='bi bi-apple' viewBox='0 0 16 16'>
                  <path
                    d='M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282' />
                  <path
                    d='M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282' />
                </svg>);
              } else if (record.os === 'Amazon') {
                return (
                  <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' fill='#FF9900' className='bi bi-amazon'
                       viewBox='0 0 16 16'>
                    <path
                      d='M10.813 11.968c.157.083.36.074.5-.05l.005.005a90 90 0 0 1 1.623-1.405c.173-.143.143-.372.006-.563l-.125-.17c-.345-.465-.673-.906-.673-1.791v-3.3l.001-.335c.008-1.265.014-2.421-.933-3.305C10.404.274 9.06 0 8.03 0 6.017 0 3.77.75 3.296 3.24c-.047.264.143.404.316.443l2.054.22c.19-.009.33-.196.366-.387.176-.857.896-1.271 1.703-1.271.435 0 .929.16 1.188.55.264.39.26.91.257 1.376v.432q-.3.033-.621.065c-1.113.114-2.397.246-3.36.67C3.873 5.91 2.94 7.08 2.94 8.798c0 2.2 1.387 3.298 3.168 3.298 1.506 0 2.328-.354 3.489-1.54l.167.246c.274.405.456.675 1.047 1.166ZM6.03 8.431C6.03 6.627 7.647 6.3 9.177 6.3v.57c.001.776.002 1.434-.396 2.133-.336.595-.87.961-1.465.961-.812 0-1.286-.619-1.286-1.533M.435 12.174c2.629 1.603 6.698 4.084 13.183.997.28-.116.475.078.199.431C13.538 13.96 11.312 16 7.57 16 3.832 16 .968 13.446.094 12.386c-.24-.275.036-.4.199-.299z' />
                    <path
                      d='M13.828 11.943c.567-.07 1.468-.027 1.645.204.135.176-.004.966-.233 1.533-.23.563-.572.961-.762 1.115s-.333.094-.23-.137c.105-.23.684-1.663.455-1.963-.213-.278-1.177-.177-1.625-.13l-.09.009q-.142.013-.233.024c-.193.021-.245.027-.274-.032-.074-.209.779-.556 1.347-.623' />
                  </svg>
                );
              }
            }}
          />
          <BooleanField
            source='bot_available'
            label='In bot'
            TrueIcon={InBotTrue as any}
            FalseIcon={InBotFalse as any}
          />
          <FunctionField
            label='Package/ID'
            render={(record: any) => {
              return (
                <Link
                  target='_blank'
                  onClick={(event: any) => event.stopPropagation()}
                  style={{
                    color: theme === 'dark' ? 'lightgrey' : 'blue',
                  }}
                  to={record.url}
                >
                  {record.package}
                </Link>);
            }}
          />
          <FunctionField
            label='PF Update'
            render={(record: any) => {
              if (record.os === 'iOS') {
                return null;
              } else {
                return (<TimerField />);
              }
            }}
          />
          <FunctionField
            label='Source'
            render={(record: any) => {
              let res = '';
              for (let i = 0; i < record.source.length; i++) {
                res += `${record.source[i]} `;
              }

              return res;
            }}
          />
          <FunctionField
            label='Vertical'
            render={(record: any) => {
              if (record.vertical) {
                return <ChipField source='vertical' />
              } else {
                return null;
              }
            }}
          />
          <FunctionField
            label='Comment'
            render={(record: any) => {
              const truncatedText = record.comment ? record.comment.substring(0, 7) : '';
              const fullText = record.comment;

              return (
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>{fullText}</span>
                  }
                >
                  <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {truncatedText} {truncatedText < fullText && '...'}
                  </div>
                </Tooltip>
              );
            }}
          />
          {/*<FunctionField*/}
          {/*  label='Add. info'*/}
          {/*  render={(record: any) => {*/}
          {/*    const truncatedText = record.additionalInfo ? record.additionalInfo.substring(0, 7) : '';*/}
          {/*    const fullText = record.additionalInfo;*/}

          {/*    return (*/}
          {/*      <Tooltip*/}
          {/*        title={*/}
          {/*          <span style={{ fontSize: '14px' }}>{fullText}</span>*/}
          {/*        }*/}
          {/*      >*/}
          {/*        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>*/}
          {/*          {truncatedText} {truncatedText < fullText && '...'}*/}
          {/*        </div>*/}
          {/*      </Tooltip>*/}
          {/*    );*/}
          {/*  }}*/}
          {/*/>*/}
          <BooleanField
            source='isBaned'
            label='Ban'
            TrueIcon={BanTrueIcon as any}
            FalseIcon={RemoveIcon}
          />
          <BooleanField
            source='fbMark'
            label='FB Mark'
            TrueIcon={MarkTrueIcon as any}
            FalseIcon={RemoveIcon}
          />
          <DeleteButton
            icon={<ArrowForwardIcon/>}
            sx={{
              color: theme === 'dark' ? 'lightgrey' : 'white',
              backgroundColor: theme === 'dark' ? '' : 'purple'
            }}
            variant={theme === 'dark' ? 'text' : 'contained'}
            label='Remove'
            mutationMode='pessimistic'
          />
        </DatagridConfigurable>
      </List>
    </Box>
  );
};

export default BotAppsList;
